import React, { FC, useEffect, useState } from "react"

import { Card } from "src/components/common/card"
import { SideNav, SideNavItemProps } from "src/components/common/sideNav"

import { create } from "src/helpers/bem"

import { useLocale } from "src/states/locale"
import { mergeFilter } from "src/states/shop/filter"
import { useProducts } from "src/states/shop/products"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewNavigation.module.scss"

const bem = create(styles, "ShopOverviewNavigation")

export type ShopOverviewNavigationProps = {
  messages: TranslationMessages
}

export const ShopOverviewNavigation: FC<ShopOverviewNavigationProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.overview
  const locale = useLocale()
  const { defaultProducts } = useProducts()
  const [items, setItems] = useState<SideNavItemProps[]>([])

  const onClick = (type: "category" | "subCategory", category?: string) => {
    mergeFilter({
      filterBy: !category
        ? undefined
        : {
            type,
            category,
          },
    })
  }

  const loadTreeStructure = async () => {
    const defaultTreeItem = {
      id: "default",
      title: m.menu.default,
      onClick: () => onClick("category", undefined),
    }
    if (defaultProducts && defaultProducts.length > 0) {
      setItems([])
    }
    const categories = Array.from(
      new Set(defaultProducts.map((item) => item?.category)),
    )
    const subCategories = Array.from(
      new Set(defaultProducts.map((item) => item?.subCategory)),
    )
    const mappedTree = await categories.map((category) => ({
      id: category,
      title: (m.categories as any)?.[category] ?? "",
      onClick: () => onClick("category", category),
      child: subCategories
        .filter((subCategory) =>
          defaultProducts.some(
            (e) => e.category === category && e.subCategory === subCategory,
          ),
        )
        .map((subCategory) => ({
          id: subCategory,
          title: (m.categories as any)?.[subCategory] ?? "",
          onClick: () => onClick("subCategory", subCategory),
        })),
    }))
    setItems([defaultTreeItem, ...mappedTree])
  }

  useEffect(() => {
    loadTreeStructure()
  }, [m, locale])

  if (!items || items?.length === 0) {
    return null
  }
  return (
    <div className={bem()}>
      <Card size="small" title={m.title}>
        <SideNav items={items} />
      </Card>
    </div>
  )
}
