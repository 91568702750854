import { SelectChangeEvent } from "@mui/material/Select"
import React, { FC, ChangeEvent, useEffect, useMemo } from "react"

import { InputField } from "src/components/common/inputfield"
import { Select } from "src/components/common/select"

import { create } from "src/helpers/bem"
import { useFilter, mergeFilter, FilterSorting } from "src/states/shop/filter"
import { useProducts, mergeProducts } from "src/states/shop/products"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewProductsHeader.module.scss"

const bem = create(styles, "ShopOverviewProductsHeader")

export type ShopOverviewProductsHeaderProps = {
  messages: TranslationMessages
}

export const productsSortingOptions = [
  "default",
  "alphabeticUp",
  "alphabeticDown",
  "priceUp",
  "priceDown",
] as FilterSorting[]

export const ShopOverviewProductsHeader: FC<
  ShopOverviewProductsHeaderProps
> = ({ messages }) => {
  const m = messages.pages.shop.overview.header
  const { defaultProducts } = useProducts()
  const { filterBy, search, sortBy } = useFilter()

  const onFilter = async () => {
    let sortedProducts = [...defaultProducts]
    if (filterBy?.category && filterBy?.type) {
      sortedProducts = await sortedProducts.filter(
        (el) => el?.[filterBy?.type ?? "category"] === filterBy?.category,
      )
    }
    switch (sortBy) {
      case "alphabeticUp":
        sortedProducts = sortedProducts.sort((a, b) =>
          a.title.localeCompare(b.title),
        )
        break
      case "alphabeticDown":
        sortedProducts = sortedProducts.sort((a, b) =>
          b.title.localeCompare(a.title),
        )
        break
      case "priceUp":
        sortedProducts = sortedProducts.sort(
          (a, b) =>
            (a?.discountedPrice ?? a.price) - (b?.discountedPrice ?? b.price),
        )
        break
      case "priceDown":
        sortedProducts = sortedProducts.sort(
          (a, b) =>
            (b?.discountedPrice ?? b.price) - (a?.discountedPrice ?? a.price),
        )
        break
    }
    const value = search?.toLowerCase()
    let filteredProducts = sortedProducts
    if (value) {
      filteredProducts = filteredProducts.filter(
        (e) =>
          e?.title?.toLowerCase().includes(value) ||
          e?.description?.toLowerCase().includes(value),
      )
    }
    mergeProducts({
      products: filteredProducts,
    })
  }

  useEffect(() => {
    onFilter()
  }, [filterBy, sortBy, search])

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    mergeFilter({
      search: e?.target?.value ?? "",
    })
  }

  const handleChangeSorting = (e: SelectChangeEvent<unknown>) => {
    const value = e?.target?.value as FilterSorting
    mergeFilter({
      sortBy: value,
    })
  }

  const sortingItems = useMemo(
    () =>
      productsSortingOptions.map((key) => ({
        key,
        value: m.sorting.items?.[key],
      })),
    [productsSortingOptions, m.sorting.items],
  )
  return (
    <div className={bem()}>
      <h1 className={bem("headline")}>{m.search.title}</h1>
      <div className={bem("content")}>
        <InputField
          label={m.search.input}
          name="search"
          type="text"
          value={search}
          classes={{
            root: bem("input"),
          }}
          onChange={handleChangeSearch}
        />
        <Select
          items={sortingItems}
          label={m.sorting.input}
          messages={messages}
          name="sorting"
          value={sortBy}
          classes={{
            selectedItem: bem("input"),
          }}
          onChange={handleChangeSorting}
        />
      </div>
    </div>
  )
}
