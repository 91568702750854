import LinearProgress from "@mui/material/LinearProgress"
import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { useProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import styles from "./ShopOverview.module.scss"
import { ShopOverviewNavigation } from "./ShopOverviewNavigation"
import { ShopOverviewProducts } from "./ShopOverviewProducts"
import { ShopOverviewProductsHeader } from "./ShopOverviewProductsHeader"

const bem = create(styles, "ShopOverview")

export type ShopOverviewProps = {
  messages: TranslationMessages
}

export const ShopOverview: FC<ShopOverviewProps> = ({ messages }) => {
  const { loading } = useProducts()
  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <div className={bem()}>
      <div className={bem("navigation")}>
        <ShopOverviewNavigation messages={messages} />
      </div>
      <div className={bem("products")}>
        <ShopOverviewProductsHeader messages={messages} />
        <ShopOverviewProducts messages={messages} />
      </div>
    </div>
  )
}
