import React, { FC, Fragment } from "react"

import { Accordion } from "src/components/common/accordion"
import { Icon, IconName } from "src/components/common/icon"
import { Link } from "src/components/common/link"

import { create } from "src/helpers/bem"

import styles from "./SideNav.module.scss"

const bem = create(styles, "SideNav")

export type SideNavItemProps = {
  id: string
  icon?: IconName
  title: string
  href?: string
  child?: {
    title?: string
    href?: string
    onClick?: () => void
  }[]
  onClick?: () => void
}

export type SideNavProps = {
  items: SideNavItemProps[]
}

export const SideNav: FC<SideNavProps> = ({ items }) => {
  const renderChild = (childList: any[], type = "parent") => (
    <ul className={bem("list")}>
      {childList.map((item) => {
        return (
          <li key={item.title} className={bem("list__item")}>
            {item?.href ? (
              <Link href={item.href} variant="base" onClick={item?.onClick}>
                <span className={bem("list__title")}>{item.title}</span>
              </Link>
            ) : (
              <div className={bem("list__link")} onClick={item?.onClick}>
                <span className={bem("list__title")}>{item.title}</span>
              </div>
            )}
            {item.child && renderChild(item.child, "child")}
          </li>
        )
      })}
    </ul>
  )

  return (
    <div className={bem()}>
      {items?.map((item, i) => {
        return item.child ? (
          <Accordion
            key={i}
            className={bem("item__accordion")}
            id={item.id}
            classNames={{
              wrapper: bem("item__accordion__wrapper"),
              tab: bem("item__accordion__tab"),
              content: bem("item__accordion__content"),
            }}
            label={
              <div className={bem("item__accordion__label")}>
                <Icon
                  className={bem("item__accordion__icon")}
                  name={item?.icon ?? "circleArrowRight"}
                />
                <span
                  className={bem("item__accordion__title")}
                  onClick={item?.onClick}
                >
                  {item.title}
                </span>
              </div>
            }
          >
            {renderChild(item.child)}
          </Accordion>
        ) : (
          <Fragment key={i}>
            {item?.href ? (
              <Link
                href={item.href}
                variant="base"
                classNames={{
                  label: bem("item__label"),
                  link: bem("item__link"),
                }}
                onClick={item?.onClick}
              >
                <Icon
                  className={bem("item__icon")}
                  name={item?.icon ?? "circleArrowRight"}
                />
                <span className={bem("item__title")}>{item.title}</span>
              </Link>
            ) : (
              <div className={bem("item__label")} onClick={item?.onClick}>
                <Icon
                  className={bem("item__icon")}
                  name={item?.icon ?? "circleArrowRight"}
                />
                <span
                  className={bem("item__title", {
                    "has-link": !!item?.onClick,
                  })}
                >
                  {item.title}
                </span>
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
