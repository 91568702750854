import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

export type ProductVariant = {
  id: number
  tag?: string
  locale?: string
  shortTitle?: string
  title: string
  subTitle?: string
  desc?: string
  content?: string
  unit?: string
  price: number
  discountedPrice?: number
  priceLabel?: string
  currency?: string
}

export type ProductContent = {
  label: string
  node?: string
}

export type ProductImage = {
  src: string
  alt?: string
}

export type Product = {
  loading: boolean
  activeTab: number
  variants: ProductVariant[]
  content: ProductContent[]
  images?: ProductImage[]
}

const { getValue, setValue, mergeValue, useValue } =
  createSimpleGlobalState<Product>(
    {
      loading: true,
      variants: [
        {
          id: 1,
          shortTitle: "Blau",
          title: "Systemstand Typ Slim - Blau",
          subTitle: "Zusätzliche kurze Beschreibung über das Produkt.",
          unit: "m²",
          price: 122.98,
          priceLabel: "Einzelpreis",
          desc: "(Vorläufiger Preis - Änderungen vorbehalten)",
          content: "Optionale Beschreibung für die Systemstand-Blau",
        },
        {
          id: 2,
          shortTitle: "Grün",
          title: "Systemstand Typ Slim - Grün",
          subTitle: "Zusätzliche kurze Beschreibung über das Produkt.",
          unit: "m²",
          price: 131.44,
          priceLabel: "Einzelpreis",
          desc: "(Vorläufiger Preis - Änderungen vorbehalten)",
          content: "Optionale Beschreibung für die Systemstand-Grün",
        },
        {
          id: 3,
          shortTitle: "Rot",
          title: "Systemstand Typ Slim - Rot",
          subTitle: "Zusätzliche kurze Beschreibung über das Produkt.",
          unit: "m²",
          price: 144.99,
          priceLabel: "Einzelpreis",
          desc: "(Vorläufiger Preis - Änderungen vorbehalten)",
          content: "Optionale Beschreibung für die Systemstand-Rot",
        },
        {
          id: 4,
          shortTitle: "Türkis",
          title: "Systemstand Typ Slim - Türkis",
          subTitle: "Zusätzliche kurze Beschreibung über das Produkt.",
          unit: "m²",
          price: 152.99,
          priceLabel: "Einzelpreis",
          desc: "(Vorläufiger Preis - Änderungen vorbehalten)",
          content: "Optionale Beschreibung für die Systemstand-Türkis",
        },
        {
          id: 5,
          shortTitle: "Gelb",
          title: "Systemstand Typ Slim - Gelb",
          subTitle: "Zusätzliche kurze Beschreibung über das Produkt.",
          unit: "m²",
          price: 179.99,
          priceLabel: "Einzelpreis",
          desc: "(Vorläufiger Preis - Änderungen vorbehalten)",
          content: "Optionale Beschreibung für die Systemstand-Gelb",
        },
      ],
      activeTab: 0,
      content: [
        {
          label: "Beschreibung",
          node: "<h3>Spezifikation</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>",
        },
        {
          label: "Bestellbedingungen",
          node: "<h3>Bedingung 1</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><h3>Bedingung 2</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>",
        },
        {
          label: "Zusatzinformationen",
          node: "<h3>Information 1</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p><h3>Bedingung 2</h3><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>",
        },
      ],
      images: [
        {
          src: "/mocks/Slim_Basis.png",
          alt: "Product - Example",
        },
        {
          src: "/mocks/Slim_Basis_2.png",
          alt: "Product - Example",
        },
        {
          src: "/mocks/Slim_Basis_3.png",
          alt: "Product - Example",
        },
      ],
    },
    "shop-product",
  )

export {
  getValue as getProduct,
  setValue as setProduct,
  mergeValue as mergeProduct,
  useValue as useProduct,
}
