import React, { FC, useState } from "react"

import { Button } from "src/components/common/button"

import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"

import { addNotification } from "src/states/notifications"
import { CartProduct, useCart, addProductToCart } from "src/states/shop/cart"
import { useProduct } from "src/states/shop/product"
import { mergeProductFeatures } from "src/states/shop/productFeatures"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetailHeaderContent.module.scss"

const bem = create(styles, "ShopDetailHeaderContent")

export type ShopDetailHeaderContentProps = {
  messages: TranslationMessages
}

export const ShopDetailHeaderContent: FC<ShopDetailHeaderContentProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.detail.header.content
  const { activeSalesorderId, products } = useCart()
  const { variants, images } = useProduct()
  const [activeVariant, setActiveVariant] = useState(0)
  const activeProduct = variants?.[activeVariant]
  const cartProductIndex = products?.findIndex(
    (el) => el?.id === activeProduct?.id,
  )
  const cartProductExists = (cartProductIndex ?? -1) >= 0

  const handleAddToCart = () => {
    if (!cartProductExists) {
      mergeProductFeatures({
        open: true,
        product: {
          ...activeProduct,
          image: images?.[0],
        },
      })
      return
    }
    addProductToCart(activeProduct as CartProduct)
    addNotification({
      autoclose: true,
      variant: "success",
      content: messages.pages.shop.cart.snackbar.changed,
    })
  }

  const priceModifier = {
    "has-discount": !!activeProduct?.discountedPrice,
  }
  const buttonDisabled = !activeSalesorderId || activeSalesorderId === "0"
  return (
    <div className={bem()}>
      <div className={bem("content")}>
        {activeProduct?.title && (
          <h1 className={bem("content__title")}>{activeProduct.title}</h1>
        )}
        {activeProduct?.subTitle && (
          <p className={bem("content__desc")}>{activeProduct.subTitle}</p>
        )}
      </div>
      <div className={bem("price")}>
        {activeProduct?.priceLabel && (
          <span className={bem("price__label")}>
            {activeProduct.priceLabel}
          </span>
        )}
        {activeProduct?.price && (
          <div className={bem("price_wrapper")}>
            {activeProduct?.discountedPrice && (
              <span className={bem("price__discount")}>
                {formatPrice(
                  activeProduct.discountedPrice,
                  activeProduct?.locale,
                  {
                    currency: activeProduct?.currency,
                  },
                )}
              </span>
            )}
            <span className={bem("price__value", priceModifier)}>
              {formatPrice(activeProduct.price, activeProduct?.locale, {
                currency: activeProduct?.currency,
              })}
            </span>
            {activeProduct?.unit && (
              <span className={bem("price__unit")}>
                &nbsp;/&nbsp;{activeProduct?.unit}
              </span>
            )}
          </div>
        )}
        {activeProduct?.desc && (
          <span className={bem("price__desc")}>{activeProduct.desc}</span>
        )}
        {activeProduct?.content && (
          <span className={bem("price__content")}>{activeProduct.content}</span>
        )}
      </div>
      {variants && variants?.length > 1 ? (
        <div className={bem("variants")}>
          {variants.map((variant, i) => (
            <button
              key={`product-variant-${i}`}
              className={bem("variant", {
                "is-active": activeVariant === i,
              })}
              onClick={() => setActiveVariant(i)}
            >
              {variant?.tag && (
                <i className={bem("variant__tag")}>{variant.tag}</i>
              )}
              <span className={bem("variant__title")}>
                {variant?.shortTitle ?? variant?.title}
              </span>
              <span
                className={bem("variant__price", {
                  "has-discount": !!variant?.discountedPrice,
                })}
              >
                {formatPrice(
                  variant?.discountedPrice ?? variant.price,
                  variant?.locale,
                  {
                    currency: variant?.currency,
                  },
                )}{" "}
                {variant?.unit && (
                  <i className={bem("variant__price__unit")}>
                    / {variant.unit}
                  </i>
                )}
              </span>
            </button>
          ))}
        </div>
      ) : null}
      <div className={bem("button")}>
        <Button
          disabled={buttonDisabled}
          title={cartProductExists ? m.button.titleAdd : m.button.title}
          variant="contained"
          onClick={handleAddToCart}
        />
        {buttonDisabled && (
          <p className={bem("button__helper")}>{m.button.helper}</p>
        )}
      </div>
    </div>
  )
}
