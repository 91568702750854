import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetailHeader.module.scss"
import { ShopDetailHeaderContent } from "./ShopDetailHeaderContent"
import { ShopDetailHeaderImages } from "./ShopDetailHeaderImages"
import { ShopDetailHeaderNavigation } from "./ShopDetailHeaderNavigation"

const bem = create(styles, "ShopDetailHeader")

import { PagesProps } from "src/types/SharedPageProps"

export type ShopDetailHeaderProps = {
  pages: PagesProps
  messages: TranslationMessages
}

export const ShopDetailHeader: FC<ShopDetailHeaderProps> = ({
  pages,
  messages,
}) => {
  return (
    <div className={bem()}>
      <ShopDetailHeaderNavigation messages={messages} pages={pages} />
      <div className={bem("header")}>
        <ShopDetailHeaderImages />
        <ShopDetailHeaderContent messages={messages} />
      </div>
    </div>
  )
}
