import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import React, { FC, ReactNode } from "react"

import { BaseLink, BaseLinkProps } from "../base-link"
import { Card } from "../card"
import { LazyImage } from "../lazyImage"

import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"

import styles from "./ProductCard.module.scss"

const bem = create(styles, "ProductCard")

export type ProductCardImageProps = {
  src: string
  alt: string
}

export type ProductCardProps = {
  className?: string
  locale?: string
  tag?: string
  image?: ProductCardImageProps
  title: string
  description?: string
  price: number
  priceSuffix?: string
  unit?: string
  discountedPrice?: number
  currency?: string
  link?: BaseLinkProps
}

export const ProductCard: FC<ProductCardProps> = ({
  className,
  locale = "de-DE",
  tag,
  image,
  title,
  description,
  price,
  priceSuffix,
  unit,
  discountedPrice,
  currency,
  link,
}) => {
  const renderLink = (children: ReactNode, className?: string) => {
    if (!link?.href) {
      return children
    }
    return (
      <BaseLink
        {...link}
        className={className && bem(className)}
        href={link?.href}
      >
        {children}
      </BaseLink>
    )
  }

  const modifierLink = {
    "has-link": !!link?.href,
  }
  const modifierDiscount = {
    "has-discount": !!discountedPrice,
  }
  return (
    <Card
      classNames={{
        root: bem(undefined, undefined, className),
        content: bem("wrapper"),
      }}
    >
      {image && tag ? <span className={bem("tag")}>{tag}</span> : null}
      {renderLink(
        <div className={bem("image")}>
          {image?.src ? (
            <LazyImage
              alt={image?.alt ?? ""}
              className={bem("image__lazy", modifierLink)}
              src={image.src}
            />
          ) : (
            <NoPhotographyIcon
              className={bem("image__placeholder", modifierLink)}
              color="disabled"
            />
          )}
        </div>,
      )}
      <div className={bem("content")}>
        {renderLink(
          <h6 className={bem("title", modifierLink)}>{title}</h6>,
          "title__link",
        )}
        {description && <p className={bem("description")}>{description}</p>}
        <div className={bem("footer")}>
          <p className={bem("price__wrapper")}>
            {priceSuffix}&nbsp;
            {discountedPrice && (
              <span className={bem("price__discount")}>
                {formatPrice(discountedPrice, locale, {
                  currency,
                })}
              </span>
            )}
            <span className={bem("price", modifierDiscount)}>
              {formatPrice(price, locale, {
                currency,
              })}
            </span>
            {unit && <span className={bem("unit")}>&nbsp;/&nbsp;{unit}</span>}
          </p>
          {link &&
            renderLink(
              <button className={bem("button")}>
                <ArrowForwardIcon color="primary" />
              </button>,
            )}
        </div>
      </div>
    </Card>
  )
}
