import { resetAccount } from "src/states/account"
import { resetAccountForm } from "src/states/accountForm"
import {
  resetBooking,
  resetCoExhibitors,
  resetDetail,
} from "src/states/bookingDetail"
import { resetBookingDetailFiles } from "src/states/bookingDetailFiles"
import { resetBookingDetailPermissions } from "src/states/bookingDetailPermissions"
import { resetBookingDetailPlacementReject } from "src/states/bookingDetailPlacementReject"
import { resetBookings } from "src/states/bookings"
import { resetCoExhibitorEdit } from "src/states/coExhibitorEdit"
import { resetCoExhibitorInvite } from "src/states/coExhibitorInvite"
import { resetCoExhibitorSalesorder } from "src/states/coExhibitorSalesorder"
import { resetSalesorder } from "src/states/salesorder"
import { resetCart } from "src/states/shop/cart"

export const resetStates = async () =>
  new Promise((resolve) => {
    resetAccount()
    resetAccountForm()
    resetBookings()
    resetSalesorder()
    resetCoExhibitorSalesorder()
    resetCoExhibitorInvite()
    resetCoExhibitorEdit()
    resetBooking()
    resetCoExhibitors()
    resetDetail()
    resetBookings()
    resetBookingDetailFiles()
    resetBookingDetailPermissions()
    resetBookingDetailPlacementReject()
    resetCart()
    resolve("States reseted!")
  })
