import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import React, { FC, useState } from "react"

import { LazyImage, LazyImageProps } from "src/components/common/lazyImage"

import { create } from "src/helpers/bem"

import styles from "./Gallery.module.scss"

const bem = create(styles, "Gallery")

export type GalleryItemProp = LazyImageProps

export type GalleryProps = {
  className?: string
  defaultIndex?: number
  items: GalleryItemProp[]
  handleChange?: (image: LazyImageProps, index: number) => void
}

export const Gallery: FC<GalleryProps> = ({
  className,
  defaultIndex = 0,
  items,
  handleChange,
}) => {
  const [index, setIndex] = useState(defaultIndex)
  return (
    <div className={bem()}>
      {items && items?.length > 0 ? (
        items.map((image, i) => {
          const modifier = {
            "is-active": i === index,
          }
          return (
            <LazyImage
              key={`gallery-image-${i}`}
              {...image}
              className={bem("image", modifier, className)}
            />
          )
        })
      ) : (
        <div className={bem("icon")}>
          <NoPhotographyIcon
            className={bem("icon__placeholder")}
            color="disabled"
          />
        </div>
      )}
      <div className={bem("pagination")}>
        {items && items?.length > 1
          ? items?.map((image, i) => {
              const modifier = {
                "is-active": i === index,
              }
              return (
                <div
                  key={`gallery-pagination-${i}`}
                  className={bem("pagination__item", modifier)}
                  role="button"
                  tabIndex={i}
                  onClick={() => {
                    handleChange?.(image, i)
                    setIndex(i)
                  }}
                >
                  <LazyImage {...image} />
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}
