import { useIsAuthenticated } from "@azure/msal-react"
import LinearProgress from "@mui/material/LinearProgress"
import moment from "moment"
import { useRouter } from "next/router"
import React, { useEffect, useState, FC, MouseEvent } from "react"

import { Table, TableData } from "src/components/common/table"

import { create } from "src/helpers/bem"

import { getSalesorder } from "src/queriesXRM/salesoder"

import { mergeBooking } from "src/states/bookingDetail"
import { setBookings, useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"

import { SharedPageProps } from "src/types/SharedPageProps"
import { xRMApiSalesorder } from "src/types/xRM"

import styles from "./BookingOverview.module.scss"

const bem = create(styles, "BookingOverview")

export const BookingOverview: FC<SharedPageProps> = ({ messages }) => {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const isAuthenticated = useIsAuthenticated()
  const bookings = useBookings()
  const locale = useLocale()

  const loadBookings = async () => {
    setLoading(true)
    const bookingResults = await getSalesorder()
    const formatedBookings = bookingResults.map((el: xRMApiSalesorder) => ({
      id: el?.id,
      name: el?.orderNumber,
      exhibition: el?.exhibition?.name,
      orderType: el?.orderType,
      exhibitor: el?.clientAddressAccount1,
      phase: el?.phase,
      createdOn: el?.createdOn ? moment(el?.createdOn).format("LLL") : null,
      coexhibitorCount: el?.coexhibitorCount,
    }))
    setLoading(false)
    setBookings(formatedBookings)
  }

  useEffect(() => {
    if (isAuthenticated) {
      loadBookings()
    }
  }, [isAuthenticated, locale])

  const handleSearchTable = (value: string, data: TableData[]) => {
    const Value = value.toLowerCase()
    return data.filter((item) => {
      if (!Value) return true
      if (
        item.name?.toLowerCase()?.includes(Value) ||
        (item.exhibition as string)?.toLowerCase()?.includes(Value) ||
        (item.exhibitor as string)?.toLowerCase()?.includes(Value) ||
        (typeof item?.client === "string" &&
          item?.client?.toLowerCase()?.includes(Value))
      ) {
        return true
      }
    })
  }

  const handleClickTableCell = (e: MouseEvent<unknown>, name: string) => {
    const bookingId = bookings.filter((el) => el.name === name)?.[0]?.id
    mergeBooking({
      id: bookingId?.toString() ?? "",
    })
    let bookingDetailURL = "/en/booking-details"
    switch (locale) {
      case "de":
        bookingDetailURL = "/de/buchungsdetails"
        break
      default:
        bookingDetailURL = "/en/booking-details"
        break
    }
    router.push(bookingDetailURL)
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }

  return (
    <div className={bem()}>
      {bookings && bookings.length > 0 ? (
        <div className={bem("table")}>
          <Table
            cellTranslations={messages.pages.booking.overview.table}
            data={bookings}
            handleClickCell={handleClickTableCell}
            handleSearch={handleSearchTable}
            messages={messages}
            order="desc"
            orderBy="createdOn"
          />
        </div>
      ) : (
        <p className={bem("description")}>
          {messages.pages.booking.overview.noData}
        </p>
      )}
    </div>
  )
}
