import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./ShopDetail.module.scss"
import { ShopDetailContent } from "./ShopDetailContent"
import { ShopDetailHeader } from "./ShopDetailHeader"

const bem = create(styles, "ShopDetail")

import { PagesProps } from "src/types/SharedPageProps"

export type ShopDetailProps = {
  pages: PagesProps
  messages: TranslationMessages
}

export const getProductIdParam = (): string => {
  const url = window.location.search
  const params = new URLSearchParams(url)
  const productId = params.get("id") as string
  return productId
}

export const ShopDetail: FC<ShopDetailProps> = ({ pages, messages }) => {
  return (
    <div className={bem()}>
      <div>
        <ShopDetailHeader messages={messages} pages={pages} />
      </div>
      <div>
        <ShopDetailContent />
      </div>
    </div>
  )
}
