import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, useEffect, useState } from "react"

import {
  AdvancedForm,
  AdvancedFormStepProps,
} from "src/components/common/advancedForm"

import { formatFormData } from "src/helpers/form"
import { getFilesById } from "src/queriesXRM/file"
import { getForm, postForm } from "src/queriesXRM/form"
import { getInvitationActiveBySalesorderId } from "src/queriesXRM/invite"
import {
  setCoExhibitorSalesorderActiveStep,
  mergeCoExhibitorSalesorderField,
  setCoExhibitorSalesorderSteps,
  useCoExhibitorSalesorder,
  getCoExhibitorSalesorder,
  mergeCoExhibitorSalesorderStepById,
  mergeCoExhibitorSalesorderFormById,
  mergeCoExhibitorSalesorderFieldById,
  resetCoExhibitorSalesorder,
} from "src/states/coExhibitorSalesorder"
import { useLocale } from "src/states/locale"
import { addNotification } from "src/states/notifications"
import { TranslationMessages } from "src/translations"
import { xRMApiFile } from "src/types/xRM"
import { xRMApiField, xRMApiStep } from "src/types/xRM"

export type CoExhibitorBookingFormProps = {
  messages: TranslationMessages
}

export const CoExhibitorBookingForm: FC<CoExhibitorBookingFormProps> = ({
  messages,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.standBooking
  const router = useRouter()
  const locale = useLocale()
  const { salesorderId, activeStep, steps } = useCoExhibitorSalesorder()

  const missingInvitationRedirect = async () => {
    switch (locale) {
      case "de":
        await router.push("/")
        break
      default:
        await router.push("/en/homepage")
        break
    }
    addNotification({
      autoclose: true,
      variant: "warning",
      code: "invitation/not/found",
    })
  }

  const validateInvitation = async () => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    const salesorderId = params.get("invitationcode") as string
    if (!salesorderId) {
      await missingInvitationRedirect()
      return
    }
    const activeInvitation = await getInvitationActiveBySalesorderId(
      salesorderId,
    )
    if (!activeInvitation) {
      await missingInvitationRedirect()
    }
  }

  const loadForm = async () => {
    const data = await getCoExhibitorSalesorder()
    const form = await getForm("CoExhibitor")

    if (data.steps.length !== 0) {
      form.map((step: xRMApiStep) => {
        mergeCoExhibitorSalesorderStepById(
          {
            id: step.id,
            position: step.position,
            label: step.label,
            dependencies: step.dependencies,
          },
          step.id,
        )
        return step?.forms?.map((form) => {
          mergeCoExhibitorSalesorderFormById(
            {
              id: form.id,
              position: form.position,
              label: form.label,
              helperText: form.helperText,
              fields: form.fields,
            },
            step.id,
            form.id,
          )
          return form?.fields?.map(async (field) => {
            let Field = field
            delete Field.conditions
            delete Field.value
            if (Field.type === "file") {
              const defaultFiles = await getFilesById(salesorderId)
              const filteredDefaultFiles = defaultFiles?.filter(
                (f: xRMApiFile) => f.formControl === "",
              ) // Before filtered name of field
              Field = {
                ...Field,
                files: filteredDefaultFiles,
              } as xRMApiField
            }
            return mergeCoExhibitorSalesorderFieldById(
              {
                ...Field,
              },
              step.id,
              form.id,
              field.id,
            )
          })
        })
      })
    } else {
      setCoExhibitorSalesorderSteps(form)
    }
    setLoading(false)
  }

  useEffect(() => {
    setCoExhibitorSalesorderActiveStep(0)
    validateInvitation()
  }, [])

  useEffect(() => {
    setLoading(true)
    loadForm()
  }, [locale])

  const onSendForm = async () => {
    setLoading(true)
    const url = window.location.search
    const params = new URLSearchParams(url)
    const parentSalesorderId = params.get("invitationcode") as string
    if (!parentSalesorderId) {
      setLoading(false)
      addNotification({
        variant: "error",
        autoclose: true,
      })
      return
    }
    const data = await formatFormData(
      "CoExhibitor",
      activeStep,
      steps,
      parentSalesorderId,
      salesorderId,
    )
    let redirectURL = ""
    switch (locale) {
      case "de":
        redirectURL = "/de/mitaussteller-buchungsuebersicht"
        break
      default:
        redirectURL = "/en/co-exhibitor-booking-overview"
        break
    }
    return postForm(data)
      .then(() => {
        resetCoExhibitorSalesorder()
        router.push(redirectURL)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <AdvancedForm
      activeStep={activeStep}
      data={steps as AdvancedFormStepProps[]}
      formMessages={m}
      handleChangeActiveStep={setCoExhibitorSalesorderActiveStep}
      handleMergeField={mergeCoExhibitorSalesorderField}
      id={salesorderId}
      messages={messages}
      buttons={{
        backButtonLabel: m.buttons.back,
        nextButtonLabel: m.buttons.next,
        finishButtonLabel: m.buttons.finish,
      }}
      onSendForm={onSendForm}
    />
  )
}
