import React, { FC } from "react"

import { Tabs, TabItem } from "src/components/common/tabs"

import { create } from "src/helpers/bem"

import { useProduct } from "src/states/shop/product"

import styles from "./ShopDetailContent.module.scss"

const bem = create(styles, "ShopDetailContent")

export const ShopDetailContent: FC = () => {
  const { activeTab, content } = useProduct()
  return (
    <div className={bem()}>
      <Tabs
        className={bem("tabs")}
        id="product-description-tabs"
        items={content as TabItem[]}
        value={activeTab}
      />
    </div>
  )
}
