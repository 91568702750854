import React, { FC } from "react"

import { Gallery, GalleryItemProp } from "src/components/common/gallery"

import { create } from "src/helpers/bem"
import { useProduct } from "src/states/shop/product"

import styles from "./ShopDetailHeaderImages.module.scss"

const bem = create(styles, "ShopDetailHeaderImages")

export const ShopDetailHeaderImages: FC = () => {
  const { images } = useProduct()
  return (
    <div className={bem()}>
      <Gallery items={images as GalleryItemProp[]} />
    </div>
  )
}
