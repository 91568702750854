import React, { FC, useMemo } from "react"

import { ProductCard } from "src/components/common/productCard"

import { create } from "src/helpers/bem"

import { useProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewProducts.module.scss"

const bem = create(styles, "ShopOverviewProducts")

export type ShopOverviewProductsProps = {
  messages: TranslationMessages
}

export const ShopOverviewProducts: FC<ShopOverviewProductsProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.overview.products
  const { products } = useProducts()
  const memorizedProducts = useMemo(() => [...products], [products])
  return (
    <div className={bem()}>
      {memorizedProducts && memorizedProducts?.length > 0
        ? memorizedProducts.map((product, i) => (
            <ProductCard
              key={i}
              className={bem("product")}
              priceSuffix={product?.variants ? m.suffix : product?.priceSuffix}
              {...product}
            />
          ))
        : null}
    </div>
  )
}
